<template>
  <v-list-item class="auction-item">
    <v-list-item-avatar size="70" tile>
      <v-img contain :src="item.image_url" />
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title>{{ item.name }}</v-list-item-title>
      <v-list-item-subtitle class="price">${{ item.base_price_per_unit }}</v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-btn @click="buyClick" class="buy" elevation="3">{{ $t('buy') }}</v-btn>
    </v-list-item-action>
    <buy-lot :item="item" ref="dialog" :key="item.id" />
  </v-list-item>
</template>
<script>
const BuyLot = () => import('@/components/Auction/Grower/Modals/BuyLot')

export default {
  name: 'ListInventoryItem',
  components: {
    BuyLot
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    buyClick () {
      this.$refs.dialog.openDialog()
    }
  }
}
</script>
<style lang="scss" scoped>
.auction-item {
  border: 1px solid #BDE5DD;
  border-radius: 10px;
  padding-left: 0!important;

  & .v-list-item__avatar {
    margin-top: 0;
    margin-bottom: 0;
  }

  & .price {
    font-weight: 700;
    color: #0BCA7C!important;
  }

  & .v-btn.buy {
    font-weight: 700;
    font-size: 12px;
    color: #009357;
    background: #FFFFFF;
    border: 2px solid #009357;
    border-radius: 5px;

    &:hover {
      color: #FFFFFF;
      background: #009357;
    }
  }
}
</style>
