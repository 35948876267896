<template>
  <v-row class="grid">
    <template v-if="!loading && lots.length">
      <v-col v-for="lot in lots" cols="12" sm="12" md="6" lg="4" :key="lot.id">
        <lot-item :key="lot.title" :item="lot" />
      </v-col>
    </template>
    <v-col v-else-if="loading">
      <loader in-block />
    </v-col>
    <v-col v-else>
      <div>
        {{ $t('no_grower_lots_found') }}
      </div>
    </v-col>
  </v-row>
</template>
<script>
import LotItem from '@/components/Auction/Grower/LotItem'
import Loader from '@/components/Core/Loader'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'GrowerTab',
  components: {
    LotItem,
    Loader
  },
  async mounted () {
    await this.loadGrowerLots(this.token)
      .finally(() => {
        this.loading = false
      })
  },
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken',
      lots: 'auction/grower/getLots'
    })
  },
  data: () => ({
    loading: true
  }),
  methods: {
    ...mapActions({
      loadGrowerLots: 'auction/grower/loadLots'
    })
  }
}
</script>
<style scoped>
.grid {
  margin: 50px 30px;
}
</style>